import React, { createContext } from "react";
import { useSelector } from "react-redux";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const SOCKET_URL = process.env.REACT_APP_SOCKET;
  const idusuario = useSelector((state) => state.dataUser.id);
  const uuidinstitucion = useSelector((state) => state.uuid);
  const isSocket = new WebSocket(
    `${SOCKET_URL}/${idusuario}/${uuidinstitucion}`
  );

  const value = { isSocket };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
