import React, { Component, Suspense, useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { useSelector } from "react-redux";

const App = () => {
  const authState = useSelector((state) => state.authUser);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Containers
  const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

  // Pages
  const Login = React.lazy(() => import("./views/pages/login/Login"));
  const LoginAdmin = React.lazy(() => import("./views/pages/login/LoginAdmin"));
  const Register = React.lazy(() => import("./views/pages/register/Register"));
  const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
  const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

  useEffect(() => {
    const auth = window.localStorage.getItem("auth");
    if (auth && auth === "true") {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        {!authState ? (
          <Routes>
            <Route
              exact
              path="/loginAdmin"
              name="Login Page Admin"
              element={<LoginAdmin />}
            />
            <Route exact path="*" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        )}
      </Suspense>
    </HashRouter>
  );
};

export default App;
