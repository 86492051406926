import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: true,
  authUser: false,
  dataUser: [],
  rnwt: {
    usus: "",
    pcao: "",
  },
  correosPendientes: 0,
};

const persistConfig = {
  key: "root", // La clave utilizada para almacenar el estado en el almacenamiento
  storage, // El motor de almacenamiento a utilizar
  // Opcional: puedes configurar otras opciones de persistencia aquí, como "whitelist" o "blacklist"
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "setRnwt":
      return { ...state, rnwt: { ...state.rnwt, ...rest } };
    case "setCorreosPendientes":
      return { ...state, correosPendientes: rest.correosPendientes };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(persistConfig, changeState);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
